.bar-container {
  margin-bottom: 80px;

  .bar {
    background-color: #fff;
  }

  .logo {
    max-width: 70px;
  }
}

.hover-dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu-position {
  margin-top: 24px !important;
}

.card-contanier {
  .card-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.content-footer {
  color: #b4b4b4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.carousel-height-300px {
  height: 300px;
}

.carousel-height-800px {
  height: 800px;
}
@keyframes square-rotate {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: square-rotate 2s linear infinite;
}

#img-trans {
  transition: opacity 1s ease-in-out;
}
@media (max-width: 600px) {
  .bar-container {
    .bar {
      justify-content: center;
    }
  }
}
